import clsx from "clsx";
import React from "react";
import { ArrowRight } from "react-feather";

const Button = ({
  label,
  textColor = "#2f3946",
  backgroundColor = "transparent",
}: {
  label: string;
  textColor?: string;
  backgroundColor?: string;
}) => {
  const borderColor = backgroundColor === "transparent" ? textColor : undefined;

  return (
    <div
      className={clsx(
        "Button",
        "w-full h-12 px-8 flex items-center",
        "border border-solid rounded-full",
        "transform transition-all ease-out shadow-sm",
        "hover:-translate-y-0.5 hover:shadow",
        "group"
      )}
      style={{ color: textColor, borderColor, backgroundColor }}
    >
      <div className="font-medium text-lg whitespace-nowrap mr-4">{label}</div>
      <div className="ml-auto flex items-center justify-center transform transition-transform ease-out group-hover:translate-x-1">
        <ArrowRight className={clsx("size-4")} />
      </div>
    </div>
  );
};

export default Button;
